import '../../node_modules/swiper/dist/css/swiper.min.css';
import Swiper from "swiper";

new Swiper('.swiper-container', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 10,
    speed: 2000,
    centeredSlides : false,
    simulateTouch: false,
    effect: 'fade',
    autoplay: {
        delay: 4000,
    },
    fadeEffect: {
        crossFade: true,
    },
    breakpoints: {
        768: {
            slidesPerView: 1,
            spaceBetween: 0
        }
    },
});

